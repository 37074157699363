import {Event} from "@models/event";
import {Action} from "@models/action";
import {Prize} from "@models/prize";

export class FullEvent extends Event {
    private _actions: Action[] = [];
    private _prizes: Prize[] = [];

    get actions(): Action[] {
        return this._actions;
    }

    set actions(value: Action[]) {
        this._actions = value;
    }

    get prizes(): Prize[] {
        return this._prizes;
    }

    set prizes(value: Prize[]) {
        this._prizes = value;
    }
}
