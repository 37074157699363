import {Injectable} from '@angular/core';
import {ActionResponse} from "@services/action.service";
import {Action} from "@models/action";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ActionRepository {
    constructor(private http: HttpClient) {
    }

    get(actionId: number): Observable<ActionResponse> {
        return this.http.get<ActionResponse>(`/actions/${actionId}`);
    }

    getAllFromClientId(clientId: string): Observable<ActionResponse[]> {
        return this.http.get<ActionResponse[]>(`/actions/client/${clientId}`)
    }

    add(action: Action, clientId: string): Observable<ActionResponse> {
        const body = {
            title: action.title,
            description: action.description,
            timer: action.timer ? {
                type: Number(action.timer.timerType),
                time: action.timer.time
            } : null,
            tokens: action.tokens,
            resource_id: action.resource.Id,
            voice_recording_id: action.voiceRecording?.id ?? null,
            client_id: clientId
        };

        return this.http.post<ActionResponse>('/actions', body);
    }

    update(action: Action): Observable<ActionResponse> {
        const body = {
            title: action.title,
            description: action.description,
            timer: action.timer ? {
                type: Number(action.timer.timerType),
                time: action.timer.time
            } : null,
            tokens: action.tokens,
            resource_id: action.resource.Id,
            voice_recording_id: action.voiceRecording?.id ?? null,
        };

        return this.http.put<ActionResponse>(`/actions/${action.id}`, body);
    }

    delete(actionId: number): Observable<void> {
        return this.http.delete<void>(`/actions/${actionId}`);
    }
}
