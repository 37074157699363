<div class="form-check form-switch d-flex">
    <div class="ms-2">
        <input #timerCheckbox type="checkbox" class="form-check-input" id="reminderSwitch" (change)="addTimer(timerCheckbox.checked)" [checked]="timer()">
        <label class="form-check-label h6 mb-2" for="reminderSwitch" i18n>Add a timer</label>
        <p class="fs-sm mb-2" i18n>
            Timer in HH:MM:SS format (e.g. 01:30:45 for a timer that lasts 1 hour, 30 minutes and 45 seconds)
        </p>
        @for (type of availableTimerTypes(); track type) {
            <p class="fs-sm mb-2">{{ type.hint }}</p>
        }

        <div class="d-flex align-items-end">
            <div class="me-3">
                <label for="select-input" class="form-label" i18n>Timer type</label>
                <select class="form-select" id="select-input" data-cy="timer-type-select">
                    @for (type of availableTimerTypes(); track type) {
                        <option [value]="type.value" (click)="switchTimerType(type.value)"
                                [selected]="availableTimerTypes().length === 1">
                            {{ type.label }}
                        </option>
                    }
                </select>
            </div>

            <div id="timepicker" data-cy="time-selector-container">
                <div class="d-flex">
                    <ngb-timepicker [ngModel]="timeToStruct()" (ngModelChange)="changeTime($event)" [seconds]="true"
                                    [required]="true"></ngb-timepicker>
                    <span class="is-valid-tick ms-3" [ngClass]="validateClass()"></span>
                </div>
            </div>
        </div>

        <!--        @if (this.validatedForm && (this.timerTypeFormControl.invalid || this.timerFormControl.invalid)) {-->
        <!--            <div class="invalid-feedback d-block" i18n>-->
        <!--                Please select a type of timer and a time higher than 0.-->
        <!--            </div>-->
        <!--        }-->
    </div>
</div>
