import {Resource} from './resource';
import {User} from './user';
import {VoiceRecording} from './voice-recording';
import {EventNotificationTime} from './event-notification-time';

export class Event {
    private _author?: User;
    private _startNotificationTime: EventNotificationTime = null;

    constructor(private _id: number | null, private _title: string | null, private _resource: Resource = null, private _voiceRecording: VoiceRecording = null) {
    }

    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get title(): string | null {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get resource(): Resource | null {
        return this._resource;
    }

    set resource(resource: Resource | null) {
        this._resource = resource;
    }

    get author(): User | null {
        return this._author;
    }

    set author(author: User | null) {
        this._author = author;
    }

    get voiceRecording(): VoiceRecording | null {
        return this._voiceRecording;
    }

    set voiceRecording(value: VoiceRecording | null) {
        this._voiceRecording = value;
    }

    get startNotificationTime(): EventNotificationTime {
        return this._startNotificationTime;
    }

    set startNotificationTime(value: EventNotificationTime) {
        this._startNotificationTime = value;
    }
}
