import {Component, inject} from '@angular/core';
import {UserService} from "@services/user.service";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [
        RouterOutlet,
        RouterLink,
        RouterLinkActive
    ],
    templateUrl: './settings.component.html',
})
export class SettingsComponent {
    protected readonly client = inject(UserService).currentClient.value;
}
