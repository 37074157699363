<h4 i18n>Profile</h4>
<form [formGroup]="profileForm()" class="needs-validation" [ngClass]="{'was-validated': profileValidated()}"
      novalidate>
    <div class="row">
        <div class="col-sm-6 mb-4">
            <label for="fn" class="form-label fs-base" i18n>First name</label>
            <input id="fn" class="form-control form-control-lg" formControlName="firstName" required>
            <div class="invalid-feedback" i18n>Please enter your client's first name!</div>
        </div>

        <div class="col-sm-6 mb-4">
            <label for="sn" class="form-label fs-base" i18n>Last name</label>
            <input id="sn" class="form-control form-control-lg" formControlName="lastName" required>
            <div class="invalid-feedback" i18n>Please enter your client's last name!</div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6 mb-4">
            <label for="yearOfBirth" class="form-label fs-base" i18n>Year of birth</label>
            <input type="number" id="yearOfBirth" class="form-control form-control-lg"
                   formControlName="yearOfBirth" pattern="[0-9]{4}" min="1900" [max]="currentYear"
                   required>
            <div class="invalid-feedback" i18n>Please provide a valid year of birth!</div>
        </div>

        <div class="col-sm-6 mb-4 d-flex flex-column">
            <label for="genderFemale" class="form-label fs-base" i18n>Gender</label>
            <div class="flex-grow-1 d-flex align-items-center">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="genderFemale" value="f"
                           formControlName="gender" required>
                    <label class="form-check-label" for="genderFemale" i18n>Female</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="genderMale" value="m"
                           formControlName="gender" required>
                    <label class="form-check-label" for="genderMale" i18n>Male</label>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-end">
        <save-button [save]="savingProfile()" (saving)="saveProfile()">Save</save-button>
    </div>
</form>
