<div class="container row h-100 custom-height m-auto pt-5">
    <aside class="col-3">
        <div class="position-sticky">
            <div class="text-center">
                @if (client.gender === 'f') {
                    <img src="assets/avatarWomen.svg" width="60">
                }
                @if (client.gender === 'm') {
                    <img src="assets/avatarMan.svg" width="60">
                }
            </div>
            <h2 class="h5 mt-2 mb-3 text-center">{{ client.firstName }}</h2>
            <div id="account-menu" class="list-group list-group-flush collapse d-md-block">
                <a routerLink="profile" class="list-group-item list-group-item-action d-flex align-items-center"
                   routerLinkActive="active">
                    <i class="bx bx-calendar fs-xl opacity-60 me-2"></i>
                    <ng-container i18n>Profile</ng-container>
                </a>
                <a routerLink="configuration" routerLinkActive="active"
                   class="list-group-item list-group-item-action d-flex align-items-center">
                    <i class="bx bx-user-circle fs-xl opacity-60 me-2"></i>
                    <ng-container i18n>Personalisation</ng-container>
                </a>
                <a routerLink="user" routerLinkActive="active"
                   class="list-group-item list-group-item-action d-flex align-items-center">
                    <i class="bx bx-cog fs-xl opacity-60 me-2"></i>
                    <ng-container i18n>User</ng-container>
                </a>
            </div>
        </div>
    </aside>
    <div class="col-9">
        <div class="col-10 mx-auto">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
