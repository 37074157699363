import {Component, inject} from '@angular/core';
import {AuthService, User} from "@auth0/auth0-angular";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {NgClass} from '@angular/common';
import {HeaderComponent} from '../../../shared/components/header/header.component';
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {PaymentService} from '@services/payment.service';
import {combineLatest, filter} from "rxjs";
import {Plan} from "@models/plan";

@Component({
    selector: 'app-personal',
    templateUrl: './personal.component.html',
    standalone: true,
    imports: [HeaderComponent, RouterLink, RouterLinkActive, NgClass, RouterOutlet]
})
export class PersonalComponent {
    private paymentService = inject(PaymentService);
    private supervisorService = inject(CreateSupervisorService);

    user?: User;
    userSignedUp = null;
    canCreateClient = false;

    loadingPaymentButton = false;
    mustPay = false;
    paying = false;

    constructor() {
        const route = inject(ActivatedRoute);
        const router = inject(Router);
        const authService = inject(AuthService);
        const supervisorService = inject(CreateSupervisorService);
        const paymentService = inject(PaymentService);

        authService.user$.subscribe(user => (this.user = user));

        supervisorService.isUserSignedUp.subscribe((isSignedUp) => {
            this.userSignedUp = isSignedUp;
        });

        combineLatest([
            supervisorService.hasPlan,
            supervisorService.hasTrial,
            supervisorService.hasPaid,
        ])
            .pipe(
                filter(([hasPlan, hasTrial, hasPaid]) => hasPlan !== null && hasTrial !== null && hasPaid !== null)
            )
            .subscribe(([hasPlan, hasTrial, hasPaid]) => {
                this.mustPay = hasPlan && !hasTrial && !hasPaid;
                this.canCreateClient = (hasPlan) ? hasTrial || hasPaid : false;
            });

        if (route.snapshot.queryParams['payment'] === 'success') {
            paymentService.isPaying().subscribe((isPaying) => this.paying = isPaying);
            paymentService.setAsPaying();

            supervisorService.waitForSubscription().then((payed) => {
                if (!payed) {
                    return;
                }

                this.canCreateClient = true;
                paymentService.finishedPaying();
                router.navigate([]);
            })
        }
    }

    paySubscription(): void {
        this.loadingPaymentButton = true;

        this.supervisorService.hasPlan
            .pipe(filter((v) => v instanceof Plan))
            .subscribe((plan: Plan) => {
                this.paymentService.startPayment(plan).subscribe((paymentSession) => {
                    window.location.href = paymentSession.url;
                    this.loadingPaymentButton = false;
                });
            });
    }
}
