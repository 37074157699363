import {Component, inject, signal} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SaveButtonComponent} from "../../../../shared/components/save-button/save-button.component";
import {UserService} from "@services/user.service";
import {PlanningConfiguration} from "@models/planning-configuration";
import {NgClass} from "@angular/common";
import {PlanningService} from "@services/planning.service";

@Component({
    selector: 'app-configuration',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        SaveButtonComponent,
        NgClass
    ],
    templateUrl: './configuration.component.html',
})
export class ConfigurationComponent {
    private userService = inject(UserService);
    private planningService = inject(PlanningService);

    protected readonly client = this.userService.currentClient.value;

    protected readonly configurationForm = signal<FormGroup | null>(null);
    protected readonly savingConfiguration = signal<boolean>(false);
    protected readonly configurationValidated = signal<boolean>(false);

    constructor() {
        this.planningService.getConfiguration(this.client).subscribe((config: PlanningConfiguration) => {
            const settingsForm = new FormGroup({
                tokens: new FormControl(config.tokensEnabled, [Validators.required]),
                cheatExtinction: new FormControl(config.cheatExtinction, [Validators.required]),
                vocalAssistant: new FormControl(config.vocalAssistant, [Validators.required]),
            });
            this.configurationForm.set(settingsForm);
        });
    }

    saveConfiguration() {
        this.configurationValidated.set(true);
        if (!this.configurationForm().valid) {
            return;
        }

        this.savingConfiguration.set(true);

        this.client.planningConfiguration = new PlanningConfiguration(
            this.configurationForm().value.tokens,
            this.configurationForm().value.cheatExtinction,
            this.configurationForm().value.vocalAssistant,
        );

        this.planningService.saveConfiguration(this.client).subscribe(() => {
            this.savingConfiguration.set(false);
            this.configurationValidated.set(true);
        });
    }
}
