import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {BehaviorSubject, combineLatest, defaultIfEmpty, map, mergeMap, Observable, tap} from 'rxjs';
import {UserService} from './user.service';
import {ActionRepository} from "@repositories/action-repository";
import {Client} from "@models/client";
import {Action} from "@models/action";
import {Timer} from "@models/timer";
import {TimerType} from "@enums/timer-type.enum";

export interface ActionResponse {
    id: number;
    title: string;
    description: string | null;
    timer: {
        type: number;
        time: number;
    } | null;
    tokens: number;
    resource_id: number;
    voice_recording_id: number;
    user_id: string;
}

@Injectable({
    providedIn: 'root'
})
export class ActionService {
    actionList$ = new BehaviorSubject<Action[]>([]);

    constructor(
        private actionRepository: ActionRepository,
        private resourceService: ResourceService,
        private userService: UserService) {
    }

    getAllFromClientId(clientId: string): Observable<Action[]> {
        return this.actionRepository.getAllFromClientId(clientId).pipe(map((response: ActionResponse[]) => {
            return response.map((actionResponse) => this.mapActionResponseToAction(actionResponse));
        }));
    }

    get(actionId: number): Observable<Action> {
        return this.actionRepository.get(actionId).pipe(map((response: ActionResponse) => {
            return this.mapActionResponseToAction(response);
        }));
    }

    save(action: Action, client?: Client): Observable<Action> {
        if (action.id) {
            return this.actionRepository.update(action).pipe(
                map((response: ActionResponse) => this.mapActionResponseToAction(response)),
                // tap(() => this.eventService.eventUpdated$.next())
            );
        }

        return this.actionRepository.add(action, client.id).pipe(
            map((response: ActionResponse) => this.mapActionResponseToAction(response))
        );
    }

    delete(actionId: number): Observable<void> {
        return this.actionRepository.delete(actionId);
    }

    private mapActionResponseToAction(response: ActionResponse): Action {
        const timer = response.timer ? new Timer(<TimerType>response.timer.type, response.timer.time) : null;
        const action = new Action(response.id, response.title, response.description, timer, response.tokens, null);


        this.userService.getUser(response.user_id).subscribe((user) => {
            action.author = user;
        });

        this.resourceService.getResourceFromResourceId(response.resource_id).subscribe((resource) => {
            action.resource = resource;
        });

        this.resourceService.getVoiceRecording(response.voice_recording_id).subscribe((voiceRecording) => {
            action.voiceRecording = voiceRecording;
        });

        return action;
    }
}
