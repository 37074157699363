<div class="container mt-5">
    <div class="row">
        <div class="col-10 m-auto">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/client/profile"><i class="bx bx-home-alt fs-lg"></i></a>
                    </li>
                    <li class="breadcrumb-item"><a routerLink="/planning">Planning</a></li>
                    @if (action()) {
                        <li class="breadcrumb-item active" aria-current="page">{{ action().title }}</li>
                    } @else {
                        <li class="breadcrumb-item active" aria-current="page" i18n>New action</li>
                    }
                </ol>
            </nav>

            <form [formGroup]="form" class="needs-validation">
                @if (action()) {
                    <h4 i18n>Edit an action</h4>
                } @else {
                    <h4 i18n>Create an action</h4>
                }
                <div class="row">
                    <div class="col-2">
                        <app-resource-picker [resource]="resource()" (resourceChange)="changeResource($event)" [validating]="validate()"></app-resource-picker>
                    </div>
                    <div class="col-10">
                        <div class="form-floating mb-4">
                            <input #actionTitle
                                   id="action-title"
                                   class="form-control"
                                   placeholder="Title"
                                   i18n-placeholder
                                   minlength="1"
                                   maxlength="15"
                                   required
                                   formControlName="title"
                                   [ngClass]="validateInputClass(controls.title)"
                            >
                            <label for="action-title" i18n>Title</label>
                            <div class="form-text ms-3" i18n>{{ actionTitle.value.length }} / 15 allowed characters
                            </div>
                        </div>

                        <div class="form-floating mb-4">
                    <textarea #actionDescription
                              id="action-description"
                              formControlName="description"
                              class="form-control"
                              placeholder="Description"
                              i18n-placeholder
                              maxlength="40"
                              rows="5"
                              [ngClass]="validateInputClass(controls.description)"
                    ></textarea>
                            <label for="action-title" i18n>Description</label>
                            <div class="form-text ms-3" i18n>{{ actionDescription.value.length }} / 40 allowed
                                characters
                            </div>
                        </div>

                        <div class="form-floating mt-4">
                            <input class="form-control"
                                   type="number"
                                   id="number-input"
                                   min="0"
                                   formControlName="tokens"
                                   required
                                   data-cy="earned-tokens"
                                   [ngClass]="validateInputClass(controls.tokens)"
                            >
                            <label for="number-input" i18n>Earned tokens</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-10 offset-2 px-1">
                        <app-timer-selector [timer]="timer()" (timerChange)="changeTimer($event)" [validating]="saving()"></app-timer-selector>
                    </div>
<!--                    <div class="col-10 offset-2 mt-4 px-1">-->
<!--                        <app-voice-recording [formControl]="form.controls.voiceRecording"></app-voice-recording>-->
<!--                    </div>-->
<!--                    <div class="col-10 offset-2 mt-4 px-1">-->
<!--                        <div class="form-check form-switch d-flex">-->
<!--                            <div class="ms-2">-->
<!--                                <input type="checkbox" class="form-check-input" id="voiceSwitch"-->
<!--                                       [checked]="displayVoiceRecording()"-->
<!--                                       (change)="toggleVoiceRecording()">-->
<!--                                <label class="form-check-label" for="voiceSwitch">-->
<!--                                    <span class="d-block h6 mb-2" i18n>Add a recording</span>-->
<!--                                    <span class="d-block mb-2" i18n>Sound that will be played when clicking on the event (5 seconds maximum)</span>-->
<!--                                </label>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        @if (displayVoiceRecording()) {-->
<!--                            <app-voice-recording [(voiceRecording)]="action().voiceRecording"></app-voice-recording>-->
<!--                        }-->
<!--                    </div>-->
                </div>

                <div class="d-flex justify-content-between mb-5">
                    <button type="button" class="btn btn-secondary mx-1" (click)="return()" [disabled]="saving()" i18n>
                        Cancel
                    </button>
                    <div>
                        @if (action()) {
                            <button type="button" class="btn btn-outline-danger me-2" [disabled]="saving()" i18n
                                    data-bs-toggle="modal" data-bs-target="#deleteModal">
                                Delete
                            </button>
                        }

                        @if (saving()) {
                            <button type="button" class="btn btn-primary pe-none mx-1">
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                                    <span class="visually-hidden">Saving...</span>
                                </span>
                                <ng-container i18n>Saving...</ng-container>
                            </button>
                        } @else {
                            <button type="button" class="btn btn-primary" data-cy="save-event" (click)="save()"
                                    i18n>
                                Save
                            </button>
                        }
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
