import {Component, inject} from '@angular/core';
import {AuthService, User} from "@auth0/auth0-angular";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {UserService} from "@services/user.service";
import {User as UserModel} from "@models/user";
import {ToastService} from "@services/toast.service";
import {Router, RouterLink} from '@angular/router';
import {NgClass} from '@angular/common';
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {first} from "rxjs";
import {LoadingService} from "@services/loading.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    standalone: true,
    imports: [RouterLink, FormsModule, ReactiveFormsModule, NgClass]
})
export class ProfileComponent {
    private supervisorService = inject(CreateSupervisorService);
    private router = inject(Router);
    private authService = inject(AuthService);
    private userService = inject(UserService);
    private toast = inject(ToastService);
    private fb = inject(FormBuilder);

    user?: User;
    profileValidated = false;
    currentDate = new Date().toISOString().split('T')[0]
    signedUp = true;

    formProfile = this.fb.group({
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        birth_date: new FormControl('', [Validators.required, this.validateBirthYear()]),
        gender: new FormControl('', [Validators.required, this.genderValidator()]),
        eula: new FormControl(false, [Validators.requiredTrue]),
    });
    profileSaving = false;
    hasClients = false;


    constructor() {
        this.supervisorService.isUserSignedUp.subscribe((canAccessPlanForm) => {
            if (null !== canAccessPlanForm) {
                this.signedUp = canAccessPlanForm;
                LoadingService.loading$.next(false);
            }
        });

        this.supervisorService.hasTrial.subscribe((hasTrial) => {
            if (hasTrial) {
                this.userService.getClients().subscribe((clients) => {
                    this.hasClients = !!clients?.length;
                });
            }
        });
        this.supervisorService.hasPaid.subscribe((hasPaid) => {
            if (hasPaid) {
                this.userService.getClients().subscribe((clients) => {
                    this.hasClients = !!clients?.length;
                });
            }
        });

        this.authService.user$.pipe(first()).subscribe(user => {
            if (!user) {
                return;
            }

            this.user = user;
            this.formProfile.patchValue({
                first_name: user.given_name,
                last_name: user.family_name,
                birth_date: user['https://plan-timer.com/user_metadata']['date_birth'],
                gender: user['https://plan-timer.com/user_metadata']['gender'],
            });
        });
    }

    saveProfile() {
        this.profileValidated = true;

        this.formProfile.markAsTouched();
        if (this.signedUp) {
            this.formProfile.patchValue({eula: true});
        }

        if (!this.formProfile.valid) {
            return;
        }

        const c = this.formProfile.controls;
        const user = new UserModel(this.user.sub, c.first_name.value, c.last_name.value, c.gender.value, c.birth_date.value);

        this.profileSaving = true;
        this.userService.updateProfile(user).subscribe({
            next: () => {
                this.supervisorService.hasSignedUp();

                this.profileSaving = false;
                this.profileValidated = false;
                this.signedUp = true;

                this.toast.success($localize`Profile updated`);
                this.router.navigate(['/', 'account', 'plans']);
            },
            error: () => this.profileSaving = false
        });
    }

    private validateBirthYear(): ValidatorFn {
        return (control: FormControl) => {
            const birthday = new Date(control.value);
            const birthdayYear = birthday.getFullYear();

            const currentDate = new Date();
            const currentYear = currentDate.getUTCFullYear();

            return birthdayYear > 1900 && birthdayYear <= currentYear ? null : {invalidBirthYear: true};
        };
    }

    private genderValidator(): ValidatorFn {
        return (control: FormControl) => {
            const gender = control.value;

            return ['m', 'f'].includes(gender?.toLowerCase()) ? null : {invalidGender: true};
        };
    }
}
