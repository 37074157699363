import {Component} from '@angular/core';
import {Client} from "@models/client";
import { AsyncValidatorFn, FormBuilder, FormControl, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {UserService} from "@services/user.service";
import {ToastService} from "@services/toast.service";
import {Router} from "@angular/router";
import {map, retry} from "rxjs";
import {AuthService} from "@auth0/auth0-angular";
import { NgClass } from '@angular/common';
import { ValidatedControlDirective } from '../../../directives/validated-control.directive';
import {LoadingService} from "@services/loading.service";

@Component({
    selector: 'app-new-client',
    templateUrl: './new-client.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, ValidatedControlDirective, NgClass]
})
export class NewClientComponent {
    currentYear = new Date().getFullYear();

    form = this.fb.group({
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        birth_year: new FormControl('', [Validators.required, this.validateBirthYear()]),
        gender: new FormControl('', [Validators.required, this.genderValidator()]),
        username: new FormControl('', Validators.required, this.usernameValidator()),
        password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
    validatedForm = false;
    saving = false;

    constructor(private userService: UserService, private authService: AuthService, private fb: FormBuilder, private toastService: ToastService, private router: Router) {
        LoadingService.loading$.next(false);
    }

    private usernameValidator(): AsyncValidatorFn {
        return (control: FormControl) => this.userService.isUsernameValid(control.value).pipe(map((valid) => {
            return valid ? null : {usernameTaken: true};
        }));
    }

    private validateBirthYear(): ValidatorFn {
        return (control: FormControl) => {
            const birthYear = control.value;
            const currentYear = new Date().getFullYear();

            return birthYear > 1900 && birthYear <= currentYear ? null : {invalidBirthYear: true};
        };
    }

    private genderValidator(): ValidatorFn {
        return (control: FormControl) => {
            const gender = control.value;

            return ['m', 'f'].includes(gender.toLowerCase()) ? null : {invalidGender: true};
        };
    }

    save() {
        this.validatedForm = true;
        this.form.markAsDirty();

        if (!this.form.valid) {
            return;
        }

        const control = this.form.controls;
        const client = new Client(null, control.first_name.value, control.last_name.value, control.gender.value, Number(control.birth_year.value), control.username.value);
        client.password = control.password.value;

        this.saving = true;

        this.authService.getAccessTokenSilently({cacheMode: 'off'}).subscribe(() => {
            this.userService.saveClient(client).subscribe((client) => {
                if (client === true) {
                    return;
                }

                this.userService.setCurrentClient(client);
                this.saving = false;
                this.toastService.success($localize`Client created !`);

                this.userService.updateClientsList()
                    .pipe(
                        map((clients) => {
                            if (clients.length === 0) {
                                throw new Error('No clients');
                            }
                        }),
                        retry(10)
                    )
                    .subscribe(() => {
                        this.router.navigate(['/', 'client', 'events']).then();
                    });
            });
        });
    }
}
