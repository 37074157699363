import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {BehaviorSubject, combineLatest, defaultIfEmpty, map, mergeMap, Observable, Subject, tap} from 'rxjs';
import {UserService} from './user.service';
import {EventNotificationTime} from "@models/event-notification-time";
import {EventRepository, EventStatsResponse} from "@repositories/event-repository";
import {Event} from "@models/event";
import {ActionResponse} from "@services/action.service";
import {PrizeResponse} from "@services/prize.service";
import {FullEvent} from "@models/full-event";
import {Action} from "@models/action";
import {ActionMapper} from "@mappers/action-mapper";
import {Prize} from "@models/prize";
import {Timer} from "@models/timer";
import {TimerType} from "@enums/timer-type.enum";

export interface FullEventResponseCollection {
    data: FullEventResponse[];
}

export interface FullEventResponse extends EventResponse {
    actions: ActionResponse[];
    prizes: PrizeResponse[];
}

export interface EventResponse {
    id: number;
    title: string;
    resource_id: number;
    user_id: string;
    voice_recording_id: number;
    start_event: { hours: number, minutes: number } | null;
}

@Injectable({
    providedIn: 'root'
})
export class EventService {
    editEvent$: BehaviorSubject<Event> = new BehaviorSubject(null);
    eventUpdated$ = new Subject<void>();

    constructor(
        private repository: EventRepository,
        private resourceService: ResourceService,
        private userService: UserService) {
    }

    allEventsFromClientId(clientId: string): Observable<FullEvent[]> {
        return this.repository.getFromClient(clientId).pipe(mergeMap((eventResponses: FullEventResponseCollection) => {
            return combineLatest(eventResponses.data.map((eventResponse) => this.mapFullEventResponseToEvent(eventResponse)));
        }));
    }

    get(eventId: number): Observable<FullEvent> {
        return this.repository.get(eventId).pipe(mergeMap((response: FullEventResponse) => this.mapFullEventResponseToEvent(response)));
    }

    save(event: FullEvent, clientId?: string): Observable<FullEvent> {
        if (event.id) {
            return this.repository.update(event).pipe(
                mergeMap((response: FullEventResponse) => this.mapFullEventResponseToEvent(response)),
                tap(() => this.eventUpdated$.next())
            );
        }

        return this.repository.add(event, clientId).pipe(
            mergeMap((response) => this.mapFullEventResponseToEvent(response))
        );
    }

    getClientFullEventList(clientId: string, date: Date): Observable<FullEvent[]> {
        return this.repository.getClientEventListOnDate(clientId, date).pipe(
            map((response: FullEventResponseCollection) => response.data),
            mergeMap((response) => this.handleGetClientFullEventList(response))
        );
    }

    private handleGetClientFullEventList(events: FullEventResponse[]): Observable<FullEvent[]> {
        return combineLatest(events.map((fullEventResponse) => (
            this.mapFullEventResponseToEvent(fullEventResponse)
        ))).pipe(defaultIfEmpty([]));
    }

    saveClientList(events: Event[], clientId: string, date: Date): Observable<boolean> {
        return this.repository.saveClientList(events, clientId, date);
    }

    delete(eventId: number): Observable<boolean> {
        return this.repository.delete(eventId);
    }

    private mapEventResponseToEvent(response: EventResponse): Observable<Event> {
        const resource$ = this.resourceService.getResourceFromResourceId(response.resource_id);
        const voiceRecording$ = this.resourceService.getVoiceRecording(response.voice_recording_id);
        const user$ = this.userService.getUser(response.user_id);

        return combineLatest([resource$, voiceRecording$, user$]).pipe(map(([resource, voiceRecording, user]) => {
            const event: Event = new Event(response.id, response.title, null);
            event.startNotificationTime = response.start_event ? new EventNotificationTime(response.start_event.hours, response.start_event.minutes) : null;
            event.resource = resource;
            event.voiceRecording = voiceRecording;
            event.author = user;

            return event;
        }));
    }

    private mapFullEventResponseToEvent(response: FullEventResponse): Observable<FullEvent> {
        const event$ = this.mapEventResponseToEvent(response);
        const actions$ = combineLatest(response.actions.map((actionResponse: ActionResponse) => this.mapActionResponseToAction(actionResponse))).pipe(defaultIfEmpty([]));
        const prizes$ = combineLatest(response.prizes.map((prizeResponse: PrizeResponse) => this.mapPrizeResponseToPrize(prizeResponse))).pipe(defaultIfEmpty([]));

        return combineLatest([event$, actions$, prizes$]).pipe(
            map(([event, actions, prizes]) => {
                const fullEvent = new FullEvent(event.id, event.title, event.resource, event.voiceRecording);
                fullEvent.startNotificationTime = response.start_event ? new EventNotificationTime(response.start_event.hours, response.start_event.minutes) : null;
                fullEvent.author = event.author;
                fullEvent.actions = actions;
                fullEvent.prizes = prizes;

                return fullEvent;
            })
        );
    }

    private mapActionResponseToAction(actionResponse: ActionResponse): Observable<Action> {
        const resource$ = this.resourceService.getResourceFromResourceId(actionResponse.resource_id);
        const voiceRecordings$ = this.resourceService.getVoiceRecording(actionResponse.voice_recording_id);
        const user$ = this.userService.getUser(actionResponse.user_id);

        return combineLatest([resource$, voiceRecordings$, user$]).pipe(map(([resource, voiceRecording, user]) => {
            const action = ActionMapper.makeFromResponse(actionResponse);
            action.resource = resource;
            action.voiceRecording = voiceRecording;
            action.author = user;

            return action;
        }));
    }

    private mapPrizeResponseToPrize(response: PrizeResponse): Observable<Prize> {
        const resource$ = this.resourceService.getResourceFromResourceId(response.resource_id);
        const voiceRecordings$ = this.resourceService.getVoiceRecording(response.voice_recording_id);
        const user$ = this.userService.getUser(response.user_id);

        return combineLatest([resource$, voiceRecordings$, user$]).pipe(map(([resource, voiceRecording, user]) => {
            const timer = response.time ? new Timer(TimerType.TIMETIMER, response.time) : null;

            const prize = new Prize(response.id, response.title, timer, response.token_cost, resource, voiceRecording);
            prize.author = user;

            return prize;
        }));
    }
}
