import {Component, computed, inject, signal} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from '@auth0/auth0-angular';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {UserService} from "@services/user.service";
import {Client} from "@models/client";
import {UserType} from "@enums/user-type.enum";
import {User} from "@models/user";
import {decodeJwt} from "jose";
import {CreateSupervisorService} from "@services/create-supervisor.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive]
})
export class HeaderComponent {
    private authService = inject(AuthService);
    private cookie = inject(CookieService);
    private userService = inject(UserService);
    private router = inject(Router);

    statisticsAccess = false;

    clients: Client[];
    currentUser: User;

    currentClient = signal<Client>(undefined);
    currentClientName = computed(() => `${this.currentClient().firstName} ${this.currentClient().lastName}`);

    constructor() {
        inject(CreateSupervisorService).hasTrial.subscribe((hasTrial) => {
            if (hasTrial) {
                this.updateUserInfo();
            }
        });

        inject(CreateSupervisorService).hasPaid.subscribe((hasPayed) => {
            if (hasPayed) {
                this.updateUserInfo();
            }
        });
    }

    private updateUserInfo(): void {
        this.authService.getAccessTokenSilently().subscribe((token) => {
            if (token) {
                const decodedToken = <{ permissions: string[] }>decodeJwt(token);
                this.statisticsAccess = decodedToken.permissions.includes('read:statistics');
            }
        });

        this.authService.user$.subscribe((data) => {
            const type = data['https://plan-timer.com/roles'][0] as UserType;
            const userInfo = data['https://plan-timer.com/user_metadata'];

            const user = new User(data.sub, data.given_name, data.family_name, userInfo.gender, userInfo.date_birth, [type]);
            user.emailVerified = data.email_verified;
            user.email = data.email;

            this.currentUser = user;

            this.userService.getClients().subscribe((clients) => this.clients = clients);
            this.userService.currentClient.subscribe((client) => this.currentClient.set(client));
        });
    }

    chooseClient(client: Client): void {
        this.currentClient.set(client);
        this.userService.setCurrentClient(client);
        this.router.navigate(['/', 'planning']);
    }

    logout(): void {
        this.cookie.deleteAll('/');
        localStorage.removeItem('clients');
        this.authService.logout();
    }
}
