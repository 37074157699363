import {ActivatedRoute, ParamMap, Router, RouterLink} from "@angular/router";
import {Location} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Component, ElementRef, inject, input, signal, viewChild} from "@angular/core";
import {Resource} from "@models/resource";
import {ResourcePickerComponent} from "@components/front/resource-picker/resource-picker.component";
import {Timer} from "@models/timer";
import {TimerSelectorComponent} from "@components/front/timer-selector/timer-selector.component";
import {VoiceRecordingComponent} from "@components/front/voice-recording/voice-recording.component";
import {ResourceService} from "@services/resource.service";
import {ToastService} from "@services/toast.service";
import {Prize} from "@models/prize";
import {PrizeService} from "@services/prize.service";
import {TimerType} from "@enums/timer-type.enum";

@Component({
    selector: 'app-edit-prize',
    templateUrl: './edit-prize.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ResourcePickerComponent,
        TimerSelectorComponent,
        VoiceRecordingComponent,
        RouterLink
    ]
})
export class EditPrizeComponent {
    protected readonly close = viewChild.required<ElementRef<HTMLButtonElement>>('close');

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private prizeService = inject(PrizeService);
    private resourceService = inject(ResourceService);
    private toastService = inject(ToastService);
    private location = inject(Location);

    protected readonly prize = signal<Prize | null>(null);
    protected readonly resource = signal<Resource>(this.resourceService.getDefaultResource());
    protected readonly timer = signal<Timer | null>(null);
    protected readonly validate = signal(false);
    protected readonly displayVoiceRecording = signal<boolean>(false);
    protected readonly saving = signal<boolean>(false);

    protected readonly form = new FormGroup({
        title: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(15)]),
        tokens: new FormControl<number>(0, [Validators.min(0)]),
    });

    protected readonly availableTimerTypes = [
        {value: TimerType.TIMETIMER, label: 'Time timer', hint: 'Minuteur: doit effectuer l\'action avant la fin.'},
    ];

    constructor() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const prizeId = Number(params.get('id'));
            if (!prizeId) {
                return;
            }

            this.prizeService.get(prizeId).subscribe((prize) => {
                this.prize.set(prize);
                this.timer.set(prize.timer);
                this.resource.set(prize.resource);

                this.form.patchValue({
                    title: prize.title,
                    tokens: prize.tokenCost,
                });
            });
        });
    }

    toggleVoiceRecording() {
        this.displayVoiceRecording.set(!this.displayVoiceRecording());
    }

    save() {
        this.validate.set(true);

        if (!(this.form.valid && this.resource())) {
            return;
        }

        this.saving.set(true);

        const prize = new Prize(
            this.prize()?.id ?? null,
            this.form.value.title,
            this.timer(),
            this.form.value.tokens,
            this.resource(),
        );

        this.prizeService.save(prize).subscribe({
            next: (updatedPrize) => {
                this.toastService.success($localize`Prize saved`);
                if (prize.id) {
                    this.location.back();
                    return;
                }

                this.router.navigateByUrl(`/prize/${updatedPrize.id}/edit`);
            },
            error: () => {
                this.saving.set(false);
                this.toastService.error($localize`An error occurred while saving the prize`);
            }
        });
    }

    changeTimer(timer: Timer | null) {
        this.timer.set(timer);
    }

    changeResource(resource: Resource) {
        this.resource.set(resource);
    }

    return() {
        this.location.back();
    }
}
