import {Component, inject, signal} from '@angular/core';
import {UserService} from "@services/user.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SaveButtonComponent} from "../../../../shared/components/save-button/save-button.component";
import {NgClass} from "@angular/common";
import {ToastService} from "@services/toast.service";

@Component({
    selector: 'app-user',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        SaveButtonComponent,
        NgClass
    ],
    templateUrl: './user.component.html',
})
export class UserComponent {
    private userService = inject(UserService);
    private toastService = inject(ToastService);

    protected readonly client = this.userService.currentClient.value;

    protected readonly userForm = signal<FormGroup | null>(null);
    protected readonly savingUser = signal<boolean>(false);
    protected readonly userValidated = signal<boolean>(false);

    constructor() {
        const userForm = new FormGroup({
            username: new FormControl({value: this.client.username, disabled: true}, [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });
        this.userForm.set(userForm);
    }

    saveUser() {
        this.userValidated.set(true);
        if (!this.userForm().valid) {
            return;
        }

        this.savingUser.set(true);

        this.client.password = this.userForm().value.password;
        this.userService.saveClient(this.client).subscribe({
            next: () => {
                this.savingUser.set(false);
                this.userValidated.set(false);
                this.toastService.success('User saved');
            },
            error: () => {
                this.savingUser.set(false);
                this.userValidated.set(false);
                this.toastService.error($localize`Error saving user`);
            }
        });
    }
}
