import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {Plan} from "@models/plan";
import {UserService} from "@services/user.service";
import {ToastService} from "@services/toast.service";
import {FormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {Router} from "@angular/router";
import {LoadingService} from "@services/loading.service";
import {combineLatest, filter} from "rxjs";

@Component({
    selector: 'app-plan',
    standalone: true,
    imports: [
        FormsModule,
        NgClass
    ],
    templateUrl: './plan.component.html',
})
export class PlanComponent {
    private userService = inject(UserService);
    private toast = inject(ToastService);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private createSupervisorService = inject(CreateSupervisorService);
    private router = inject(Router);

    planJustChosen = false;
    chosenPlan: Plan | null = null;
    plans = [
        new Plan('family'),
        new Plan('pro'),
    ];

    onTrial = false;
    hasAlreadyPaid = false;

    constructor() {
        combineLatest([
            this.createSupervisorService.hasPlan,
            this.createSupervisorService.hasTrial,
            this.createSupervisorService.hasPaid,
        ])
            .pipe(
                filter(([hasPlan, hasTrial, hasPaid]) => hasPlan !== null && hasTrial !== null && hasPaid !== null)
            )
            .subscribe(([hasPlan, hasTrial, hasPaid]) => {
                this.chosenPlan = <Plan>hasPlan;
                this.onTrial = hasTrial;
                this.hasAlreadyPaid = hasPaid;

                LoadingService.loading$.next(false);
            });

    }

    choosePlan(plan: Plan): void {
        this.planJustChosen = true;

        const previousPlan = this.chosenPlan;
        this.chosenPlan = plan;

        this.changeDetectorRef.detectChanges();

        this.userService.choosePlan(plan).subscribe(async () => {
            const newPlan = await this.createSupervisorService.waitForNewPlan(previousPlan, plan);
            this.planJustChosen = false;

            if (newPlan && newPlan?.id === plan.id) {
                this.toast.success($localize`The plan has been changed`);

                if (!previousPlan || this.createSupervisorService.hasTrial.value || this.createSupervisorService.hasPaid.value) {
                    this.userService.getClients().subscribe((clients) => {
                        if (!clients?.length) {
                            this.router.navigate(['/', 'account', 'client', 'new']);
                        }
                    });
                }
                return;
            }

            this.toast.error($localize`The plan could not be changed`);
        });
    }

}
