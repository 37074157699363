import {Component, computed, inject, input, output} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TimerType} from '@enums/timer-type.enum';
import {NgbTimepickerConfig, NgbTimepickerModule, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgClass} from '@angular/common';
import {Timer} from "@models/timer";

@Component({
    selector: 'app-timer-selector',
    templateUrl: './timer-selector.component.html',
    styleUrls: ['./timer-selector.component.css'],
    providers: [
        NgbTimepickerConfig,
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgbTimepickerModule, NgClass]
})
export class TimerSelectorComponent {
    availableTimerTypes = input([
        {value: TimerType.TIMETIMER, label: 'Time timer', hint: 'Minuteur: doit effectuer l\'action avant la fin.'},
        {
            value: TimerType.RTIMETIMER,
            label: 'Reverse time timer',
            hint: 'Minuteur inversé: doit effectuer l\'action au moins pendant un certain temps.'
        }
    ]);
    timer = input<Timer|null>();
    validating = input(false);

    timerChange = output<Timer|null>();

    protected readonly timeToStruct = computed(() => {
        if (!this.timer()) {
            return {hour: 0, minute: 0, second: 0};
        }

        const da = new Date(0, 0, 0, 0, 0, this.timer().time);
        return {hour: da.getHours(), minute: da.getMinutes(), second: da.getSeconds()};
    });
    protected readonly validateClass = computed(() => {
        if (!this.timer()) {
            return null;
        }

        return this.timer().time <= 0 ? {invalidTime: true} : null;
    });

    constructor() {
        inject(NgbTimepickerConfig).spinners = false;
    }

    protected switchTimerType(type: TimerType): void {
        this.timerChange.emit(new Timer(type, this.timer().time));
    }

    protected changeTime(time: NgbTimeStruct) {
        const seconds = time.hour * 3600 + time.minute * 60 + time.second;
        this.timerChange.emit(new Timer(this.timer().timerType, seconds));
    }

    protected addTimer(checked: boolean) {
        this.timerChange.emit(checked ? new Timer(TimerType.TIMETIMER, 0) : null);
    }
}
