import {Component, inject} from '@angular/core';
import {LoadingService} from "@services/loading.service";
import {RouterModule} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterModule]
})
export class AppComponent {
    loading = true;

    constructor() {
        LoadingService.loading$.subscribe((loading) => this.loading = loading);

        const authService = inject(AuthService);

        authService.error$.subscribe((e) => {
            authService.loginWithRedirect();
        });

        authService.isAuthenticated$.subscribe((isAuthenticated) => {
            if (!isAuthenticated) {
                authService.loginWithRedirect();
            }
            authService.getAccessTokenSilently().subscribe((token) => {
                LoadingService.loading$.next(!Boolean(token));
            });
        });
    }
}
