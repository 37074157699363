import {Injectable} from '@angular/core';
import {map, Observable, of, Subject, tap} from 'rxjs';
import {PlanningConfiguration} from "@models/planning-configuration";
import {PlanningRepository} from "@repositories/planning-repository";
import {Client} from "@models/client";

export declare interface PlanningConfigurationResponse {
    tokens_enabled: boolean;
    cheat_extinction: boolean;
    vocal_assistant: boolean;
}

/**
 * TODO: remove this service, the configuration should be part of the client object
 */
@Injectable({
    providedIn: 'root'
})
export class PlanningService {
    planningChange$ = new Subject<PlanningConfiguration>();

    constructor(private repository: PlanningRepository) {
    }

    private static saveConfigurationToCache(clientId: string, data: PlanningConfigurationResponse): void {
        localStorage.setItem('config-' + clientId, JSON.stringify(data));
    }

    getConfiguration(client: Client): Observable<PlanningConfiguration> {
        const localConfig = this.getConfigurationFromCache(client.id);
        if (localConfig) {
            return of(localConfig);
        }

        return this.repository.getConfiguration(client.id).pipe(
            tap((response) => PlanningService.saveConfigurationToCache(client.id, response)),
            map((response) => new PlanningConfiguration(response.tokens_enabled, response.cheat_extinction, response.vocal_assistant)),
            tap((config) => this.planningChange$.next(config)),
        );
    }

    saveConfiguration(client: Client): Observable<PlanningConfiguration> {
        const config = client.planningConfiguration;

        return this.repository.saveConfiguration(client.id, config.tokensEnabled, config.cheatExtinction, config.vocalAssistant)
            .pipe(
                tap(() => localStorage.removeItem(`config-${client.id}`)),
                map((response) => new PlanningConfiguration(response.tokens_enabled, response.cheat_extinction, response.vocal_assistant))
            );
    }

    private getConfigurationFromCache(clientId: string): PlanningConfiguration {
        const configData = <PlanningConfigurationResponse>JSON.parse(localStorage.getItem('config-' + clientId));
        if (!configData) {
            return null;
        }

        const config = new PlanningConfiguration(configData.tokens_enabled, configData.cheat_extinction, configData.vocal_assistant);
        this.planningChange$.next(config);
        return config;
    }
}
