import {Component, inject, signal} from '@angular/core';
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {UserService} from "@services/user.service";
import {EventService} from "@services/event.service";
import {FullEvent} from "@models/full-event";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {ToastService} from "@services/toast.service";
import {Prize} from "@models/prize";
import {PrizeService} from "@services/prize.service";

@Component({
    selector: 'app-set-prizes',
    standalone: true,
    imports: [
        CdkDrag,
        CdkDropList,
        RouterLink
    ],
    templateUrl: './set-prizes.component.html',
    styleUrl: './set-prizes.component.css'
})
export class SetPrizesComponent {
    private eventService = inject(EventService);
    private toast = inject(ToastService);
    private actionsChanged$ = new Subject<void>();

    protected client = inject(UserService).currentClient.value;

    allPrizes: Prize[] = [];

    event = signal<FullEvent>(null);
    prizes = signal<Prize[]>([]);
    saving = signal(false);
    prizesLoaded = signal(false);

    constructor() {
        inject(PrizeService).getPrizesFromCurrentClient().subscribe(allPrizes => {
            this.allPrizes = allPrizes;
            this.prizesLoaded.set(true);
        });

        inject(ActivatedRoute).params.subscribe((params) => (
            inject(EventService).get(params.id).subscribe((event) => {
                this.event.set(event);
                this.prizes.set(event.prizes)
            })
        ));

        this.actionsChanged$.pipe(debounceTime(1000)).subscribe(() => this.save());
    }

    drop(event: CdkDragDrop<any, any>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.actionsChanged$.next();
    }

    addToEvent(prize: Prize) {
        if (this.prizes().length >= 2) {
            this.toast.warn($localize`You can't have more than 2 prizes`);
            return;
        }

        if (this.prizes().map((v) => v.id).includes(prize.id)) {
            this.toast.warn($localize`This prize is already part of the event`);
            return;
        }

        this.prizes().push(prize);
        this.actionsChanged$.next();
    }

    removePrize(prize: Prize) {
        const index = this.prizes().indexOf(prize);
        this.prizes().splice(index, 1);
        this.actionsChanged$.next();
    }

    save() {
        this.saving.set(true);
        this.event().prizes = this.prizes();

        this.eventService.save(this.event()).subscribe(() => {
            this.toast.success($localize`Prizes have been affected`);
        })
    }
}
