import {CanActivateChildFn, CanActivateFn, Routes, UrlTree} from "@angular/router";
import {inject} from "@angular/core";
import {authGuardFn, AuthService} from "@auth0/auth0-angular";
import {UserType} from "@enums/user-type.enum";
import {UserService} from "@services/user.service";
import {FrontComponent} from "./front/front.component";
import {PersonalComponent} from "./account/pages/personal/personal.component";
import {ProfileComponent as AccountProfileComponent} from "./account/pages/personal/profile/profile.component";
import {NewClientComponent} from "./account/pages/personal/new-client/new-client.component";
import {StatisticsComponent} from "./statistics/statistics.component";
import {decodeJwt} from "jose";
import {PlanComponent} from "./account/pages/personal/plan/plan.component";
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {combineLatest, filter, map} from "rxjs";
import {PlanningComponent} from "./front/pages/planning/planning.component";
import {EventComponent} from "./front/pages/event/event.component";
import {SettingsComponent} from "./front/pages/settings/settings.component";
import {ProfileComponent} from "./front/pages/settings/profile/profile.component";
import {ConfigurationComponent} from "./front/pages/settings/configuration/configuration.component";
import {UserComponent} from "./front/pages/settings/user/user.component";
import {EditPrizeComponent} from "./front/pages/edit-prize/edit-prize.component";
import {EditActionComponent} from "./front/pages/edit-action/edit-action.component";
import {SetActionsComponent} from "./front/pages/event/set-actions/set-actions.component";
import {SetPrizesComponent} from "./front/pages/event/set-prizes/set-prizes.component";

const isUser: CanActivateFn | CanActivateChildFn = () => {
    const authService = inject(AuthService);

    return new Promise<boolean | UrlTree>((r) => {
        authService.user$.subscribe((user) => {
            r(user['https://plan-timer.com/roles'].includes(UserType.SUPERVISOR));
        });
    });
};

const canAccessStatistics: CanActivateFn | CanActivateChildFn = () => {
    const authService = inject(AuthService);

    return new Promise<boolean | UrlTree>((r) => {
        authService.getAccessTokenSilently().subscribe((token) => {
            if (token) {
                const decodedToken = <{ permissions: string[] }>decodeJwt(token);
                r(decodedToken.permissions.includes('read:statistics'));
                return;
            }

            r(false);
        });
    });
};

const hasClients: CanActivateFn | CanActivateChildFn = () => (
    inject(UserService).getClients().pipe(map((clients) => Boolean(clients?.length)))
);

const canCreateClient: CanActivateFn | CanActivateChildFn = () => (
    inject(CreateSupervisorService).hasPlan.pipe(filter((v) => v !== null), map((plan) => !!plan))
);

const canChoosePlan: CanActivateFn | CanActivateChildFn = () => (
    inject(CreateSupervisorService).isUserSignedUp.pipe(filter((v) => v !== null))
);

const hasActiveSubscription: CanActivateFn | CanActivateChildFn = () => {
    const createSupervisorService = inject(CreateSupervisorService);
    return combineLatest([createSupervisorService.hasPlan, createSupervisorService.hasTrial, createSupervisorService.hasPaid])
        .pipe(
            filter(([hasPlan, hasTrial, hasPaid]) => hasPlan !== null && hasTrial !== null && hasPaid !== null),
            map(([hasPlan, hasTrial, hasPaid]) => {
                return !!hasPlan && (hasTrial || hasPaid);
            })
        );
};

export const routes: Routes = [
    {pathMatch: 'full', path: '', redirectTo: 'account/profile'},
    {
        path: '',
        component: FrontComponent,
        canActivateChild: [authGuardFn, isUser, hasActiveSubscription, hasClients],
        children: [
            {
                path: 'planning',
                component: PlanningComponent,
            },
            {
                path: 'event/new',
                component: EventComponent,
            },
            {
                path: 'event/:id/edit',
                component: EventComponent,
            },
            {
                path: 'action/:id/edit',
                component: EditActionComponent,
            },
            {
                path: 'event/:id/actions',
                component: SetActionsComponent,
            },
            {
                path: 'event/:id/prizes',
                component: SetPrizesComponent,
            },
            {
                path: 'action/new',
                component: EditActionComponent,
            },
            {
                path: 'prize/:id/edit',
                component: EditPrizeComponent,
            },
            {
                path: 'prize/new',
                component: EditPrizeComponent,
            },
            {path: 'settings', redirectTo: '/settings/profile', pathMatch: 'full'},
            {
                path: 'settings',
                component: SettingsComponent,
                children: [
                    {path: 'profile', component: ProfileComponent},
                    {path: 'configuration', component: ConfigurationComponent},
                    {path: 'user', component: UserComponent},
                ]
            },
        ]
    },
    {pathMatch: 'full', path: 'account', redirectTo: 'account/profile'},
    {
        path: 'account',
        component: PersonalComponent,
        canActivateChild: [authGuardFn],
        children: [
            {pathMatch: 'full', path: 'account', redirectTo: 'account/profile'},
            {path: 'profile', component: AccountProfileComponent},
            {path: 'plans', component: PlanComponent, canActivate: [canChoosePlan]},
            {path: 'client/new', component: NewClientComponent, canActivate: [canCreateClient]}
        ]
    },
    {
        path: 'statistics',
        component: StatisticsComponent,
        canActivate: [authGuardFn, hasActiveSubscription, isUser, hasClients, canAccessStatistics],
        canActivateChild: [authGuardFn, hasActiveSubscription, isUser, hasClients, canAccessStatistics],
    }
];
