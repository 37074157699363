<div class="form-check form-switch d-flex">
  <div class="ms-2">


    <div class="card">
      <div class="audio-player card-body">
        <audio #audio></audio>

        <button type="button" (click)="toggleRec()" [disabled]="!canToggleRecord()" class="btn btn-icon btn-info shadow-info me-2">
          @if (!recording) {
            <i class="bx bx-microphone"></i>
          }
          @if (recording) {
            <i class="bx bx-stop"></i>
          }
        </button>
        @if (!playing) {
          <button type="button" class="ap-play-button btn btn-icon btn-primary shadow-primary" (click)="togglePlay()" [disabled]="!canTogglePlay()"></button>
        }
        @if (playing) {
          <button type="button" class="ap-play-button ap-pause btn btn-icon btn-primary shadow-primary" (click)="togglePlay()"></button>
        }

        <span class="ap-current-time flex-shr fw-medium mx-3 mx-lg-4">0:0{{audioTimer}}</span>
        <input type="range" class="ap-seek-slider" [max]="audioTimerMax" [value]="audioTimer">
        <span class="ap-duration flex-shr fw-medium mx-3 mx-lg-4">0:0{{audioTimerMax-audioTimer}}</span>

        <button type="button" class="btn btn-icon btn-danger" (click)="removeVoiceRecording()" [disabled]="!canDeleteRecording()">
          <i class="bx bx-trash"></i>
        </button>
      </div>
    </div>
  </div>
</div>
