<app-header></app-header>

@if (user) {
    @if (mustPay) {
        <div class="container row m-auto mt-5">
            <div class="col">
                <div class="alert alert-danger mb-4 d-flex justify-content-between" role="alert">
                    <div class="d-flex align-items-center">
                        <i class="bx bx-bell lead me-3"></i>
                        <div class="d-inline">
                            <p class="fw-bold mb-1" i18n>Your trial is finished !</p>
                            <p class="mb-0" i18n>You can still change your subscription before paying, then you will be
                                able to freely use Plan Timer.</p>
                            <p class="fw-light mb-0">Besoin de plus de temps ? Contactez-nous à <a
                                href="contact@plan-timer.com">contact&commat;plan-timer.com</a></p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                        @if (!loadingPaymentButton) {
                            <button class="btn btn-primary" i18n (click)="paySubscription()">Pay</button>
                        } @else {
                            <button class="btn btn-primary" disabled>
                        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true">
                            <span class="visually-hidden">Saving...</span>
                        </span>
                                <ng-container i18n>Pay</ng-container>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    <div class="container row h-100 custom-height m-auto mt-5">
        <aside class="col-3">
            <div class="position-sticky">
                <div class="text-center">
                    @if (user.gender === 'f') {
                        <img src="assets/avatarWomen.svg" width="60">
                    }
                    @if (user.gender === 'm') {
                        <img src="assets/avatarMan.svg" width="60">
                    }
                </div>
                <h2 class="h5 mt-2 mb-3 text-center">{{ user.given_name }} {{ user.family_name }}</h2>
                <div id="account-menu" class="list-group list-group-flush collapse d-md-block">
                    <a [routerLink]="['/', 'account', 'profile']" [routerLinkActive]="['active']"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-user-circle fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>Profile</ng-container>
                    </a>
                    <a [routerLink]="['/', 'account', 'plans']" [routerLinkActive]="userSignedUp ? ['active'] : []"
                       [ngClass]="{'text-black-50': !userSignedUp}"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-credit-card fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>Plan</ng-container>
                    </a>
                    <a [routerLink]="canCreateClient ? ['client', 'new'] : null"
                       [routerLinkActive]="canCreateClient ? ['active'] : []"
                       [ngClass]="{'text-black-50': !canCreateClient}"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-user-plus fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>New client</ng-container>
                    </a>
                </div>
            </div>
        </aside>
        <section class="col-9">
            <div class="col-10 offset-1">
                @if (paying) {
                    <div class="d-flex alert alert-primary">
                        <i class="bx bx-credit-card lead me-3"></i>
                        Your subscription is being processed. Please wait until this message disappears.
                    </div>
                }
                <router-outlet></router-outlet>
            </div>
        </section>
    </div>
}
