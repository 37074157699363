<div class="container row mt-5 m-auto">
    <div class="col-3">
        <div class="card text-white bg-info" style="cursor: pointer" (click)="openEventForm()">
            <div class="card-body">
                <h6 class="card-title text-white" i18n>New event</h6>
                <p class="card-text fs-sm" i18n>Create an event and add it to {{ client.firstName }}'s list</p>
            </div>
        </div>
        @defer (when eventsLoaded()) {
            @for (event of events; track event.id) {
                <div class="d-flex justify-content-between border rounded-3 my-3 flex-grow-1" style="height: 75px;">
                    <div class="d-flex">
                        <div class="h-100 rounded-start-3 bg-info" style="width: 20px;"></div>
                        <img [src]="event.resource.Link" class="me-3" style="height: 100%" alt="">
                        <a class="m-0 py-1" [routerLink]="['/event', event.id, 'edit']">{{ event.title }}</a>
                    </div>

                    <div class="pen rounded-end-3 d-flex justify-content-center align-items-center" style="width: 40px;"
                         (click)="addToPlanning(event)">
                        <i class="bx bx-chevrons-right fs-3"></i>
                    </div>
                </div>
            }
        } @loading (minimum 500ms) {
            <div class="d-flex justify-content-center align-items-center mt-5">
                <div class="spinner-grow text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        } @error {
            ERROR
        }
    </div>

    <div class="col-9 ps-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/client/profile"><i class="bx bx-home-alt fs-lg"></i></a></li>
                <li class="breadcrumb-item active" aria-current="page" i18n>Planning</li>
            </ol>
        </nav>

        <div class="d-flex justify-content-between align-items-center">
            <h5 class="m-0 fw-normal" i18n>Planning de {{ client.firstName }}</h5>
            <div class="d-flex">
                <input #d="ngbDatepicker" class="form-control me-2" readonly name="dp"
                       [ngModel]="dateSelector" ngbDatepicker
                       (ngModelChange)="onDateChange($event)">
                <button class="btn btn-primary btn-icon" type="button" (click)="d.toggle()">
                    <i class="bx bx-calendar"></i>
                </button>
            </div>
        </div>

        <hr class="my-3">
        @defer (when planningLoaded()) {
            <div
                (cdkDropListDropped)="drop($event)"
                [cdkDropListData]="fullEvents()"
                cdkDropList
                cdkDropListLockAxis="y"
                cdkDropListOrientation="vertical"
            >
                @for (fullEvent of fullEvents(); let idx = $index; track fullEvent.id) {
                    <app-event-line [event]="fullEvent" (removeEvent)="removeEvent(idx)"></app-event-line>
                } @empty {
                    <div class="d-flex flex-column p-3 align-items-center">
                        <p class="text-center" i18n>No event planned on this day.</p>
                        <img src="assets/add-element.svg" alt="Add events to the planning" i18n-alt width="200"
                             height="200">
                        <div class="text-center">
                            <button class="btn btn-info mt-2" (click)="openEventForm()" i18n>Create an event</button>
                        </div>
                    </div>
                }
            </div>
        } @loading (minimum 500ms) {
            <div class="d-flex justify-content-center align-items-center mt-5">
                <div class="spinner-grow text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        } @error {
            ERROR
        }
    </div>
</div>
