import {ActivatedRoute, ParamMap, Router, RouterLink} from "@angular/router";
import {Location, NgClass} from "@angular/common";
import {ActionService} from "@services/action.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Component, ElementRef, inject, signal, viewChild} from "@angular/core";
import {Resource} from "@models/resource";
import {ResourcePickerComponent} from "@components/front/resource-picker/resource-picker.component";
import {Timer} from "@models/timer";
import {TimerSelectorComponent} from "@components/front/timer-selector/timer-selector.component";
import {VoiceRecordingComponent} from "@components/front/voice-recording/voice-recording.component";
import {Action} from "@models/action";
import {ResourceService} from "@services/resource.service";
import {ToastService} from "@services/toast.service";
import {UserService} from "@services/user.service";

@Component({
    selector: 'app-edit-action',
    templateUrl: './edit-action.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ResourcePickerComponent,
        TimerSelectorComponent,
        VoiceRecordingComponent,
        RouterLink,
        NgClass
    ]
})
export class EditActionComponent {
    protected readonly close = viewChild.required<ElementRef<HTMLButtonElement>>('close');

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private actionService = inject(ActionService);
    private resourceService = inject(ResourceService);
    private toastService = inject(ToastService);
    private location = inject(Location);

    protected readonly action = signal<Action | null>(null);
    protected readonly resource = signal<Resource>(this.resourceService.getDefaultResource());
    protected readonly timer = signal<Timer | null>(null);
    protected readonly validate = signal(false);
    protected readonly displayVoiceRecording = signal<boolean>(false);
    protected readonly saving = signal<boolean>(false);

    protected readonly form = new FormGroup({
        title: new FormControl<string>('', [Validators.required, Validators.minLength(1), Validators.maxLength(15)]),
        description: new FormControl<string>('', [Validators.maxLength(40)]),
        tokens: new FormControl<number>(0, [Validators.min(0)]),
    });
    protected readonly controls = this.form.controls;

    private client = inject(UserService).currentClient.value;

    constructor() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const actionId = Number(params.get('id'));
            if (!actionId) {
                return;
            }

            this.actionService.get(actionId).subscribe((action) => {
                this.action.set(action);
                this.timer.set(action.timer);
                this.resource.set(action.resource);

                this.form.patchValue({
                    title: action.title,
                    description: action.description,
                    tokens: action.tokens,
                });
            });
        });
    }

    toggleVoiceRecording() {
        this.displayVoiceRecording.set(!this.displayVoiceRecording());
    }

    save() {
        this.validate.set(true);
        this.saving.set(true);

        if (!(this.form.valid && this.resource())) {
            this.saving.set(false);
            return;
        }

        const action = new Action(
            this.action()?.id ?? null,
            this.form.value.title,
            this.form.value.description,
            this.timer(),
            this.form.value.tokens,
            this.resource(),
        );

        this.actionService.save(action, this.client).subscribe({
            next: (updatedAction) => {
                this.toastService.success($localize`Action saved`);
                if (action.id) {
                    this.location.back();
                    return;
                }

                this.router.navigateByUrl(`/action/${updatedAction.id}/edit`);
            },
            error: () => {
                this.saving.set(false);
                this.toastService.error($localize`An error occurred while saving the action`);
            }
        });
    }

    validateInputClass(control: FormControl) {
        return {
            'is-invalid': control.invalid && this.validate(),
            'is-valid': control.valid && this.validate(),
        };
    }

    changeTimer(timer: Timer | null) {
        this.timer.set(timer);
    }

    changeResource(resource: Resource) {
        this.resource.set(resource);
    }

    return() {
        this.location.back();
    }
}
