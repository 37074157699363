@if (event()) {
    <div class="container row mt-5 mx-auto">
        @defer (when prizesLoaded()) {
            <div class="col-4 overflow-auto">
                <div class="card text-white bg-warning" style="cursor: pointer" routerLink="/prize/new">
                    <div class="card-body">
                        <h6 class="card-title text-white" i18n>New prize</h6>
                        <p class="card-text fs-sm" i18n>Create a prize and add it to {{ client.firstName }}'s
                            list</p>
                    </div>
                </div>
                @for (prize of allPrizes; track prize.id) {
                    <div class="d-flex justify-content-between border rounded-3 my-3 flex-grow-1"
                         style="height: 75px;">
                        <div class="d-flex">
                            <div class="h-100 rounded-start-3 bg-warning" style="width: 20px;"></div>
                            <img [src]="prize.resource.Link" class="me-3" style="height: 100%" alt="">
                            <a class="m-0 py-1" [routerLink]="['/prize', prize.id, 'edit']">{{ prize.title }}</a>
                        </div>

                        <div class="pen rounded-end-3 d-flex justify-content-center align-items-center"
                             style="width: 40px;"
                             (click)="addToEvent(prize)">
                            <i class="bx bx-chevrons-right fs-3"></i>
                        </div>
                    </div>
                }
            </div>

            <div class="col-8 ps-5 overflow-auto">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a routerLink="/client/profile"><i class="bx bx-home-alt fs-lg"></i></a>
                        </li>
                        <li class="breadcrumb-item"><a routerLink="/planning" i18n>Planning</a></li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/', 'event', event().id, 'edit']">{{ event().title }}</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" i18n>Affectation des récompenses</li>
                    </ol>
                </nav>

                <h5 class="fw-normal" i18n>Affectation des récompenses à l'événement <i>{{ event().title}}</i></h5>
                <div
                    (cdkDropListDropped)="drop($event)"
                    [cdkDropListData]="prizes()"
                    cdkDropList
                    cdkDropListLockAxis="y"
                    cdkDropListOrientation="vertical"
                >
                    @for (prize of prizes(); let idx = $index; track idx) {
                        <div class="d-flex align-items-center gap-3 full-event" cdkDrag>
                            <div>
                                <!-- Jetons ? Minuteur ? -->
                            </div>
                            <div class="d-flex justify-content-between border rounded-3 my-3 flex-grow-1"
                                 style="height: 75px;">
                                <div class="d-flex">
                                    <div class="h-100 rounded-start-3 bg-warning" style="width: 20px;"></div>
                                    <img [src]="prize.resource.Link" class="me-3" style="height: 100%" alt="">
                                    <div class="d-flex flex-column justify-content-between py-1">
                                        <p class="m-0">{{ prize.title }}</p>
                                        <div>
                                            <!-- badges -->
                                        </div>
                                    </div>
                                </div>
                                <div class="pen rounded-end-3 d-flex justify-content-center align-items-center"
                                     style="width: 40px;"
                                     routerLink="/prize/{{ prize.id }}/edit"
                                >
                                    <i class="bx bx-edit-alt fs-3"></i>
                                </div>
                            </div>
                            <div class="remove-event" (click)="removePrize(prize)">
                                <i class="bx bxs-x-circle fs-4"></i>
                            </div>
                        </div>
                    } @empty {
                        <div class="d-flex flex-column p-3 align-items-center">
                            <p class="text-center" i18n>No prize linked.</p>
                            <img src="assets/add-element.svg" alt="Add events to the planning" i18n-alt
                                 width="200"
                                 height="200">
                            <div class="text-center">
                                <button class="btn btn-warning mt-2" routerLink="/prize/new" i18n>Create a prize</button>
                            </div>
                        </div>
                    }
                </div>
            </div>


        } @loading (minimum 500ms) {
            <div class="d-flex justify-content-center align-items-center mt-5">
                <div class="spinner-grow text-warning" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        } @error {
            ERROR
        }
    </div>
}
