import {Component, inject, signal} from '@angular/core';
import {UserService} from "@services/user.service";
import {Client} from "@models/client";
import {EventService} from "@services/event.service";
import {FullEvent} from "@models/full-event";
import {Router, RouterLink} from "@angular/router";
import {NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {ToastService} from "@services/toast.service";
import {EventLineComponent} from "./event-line/event-line.component";

interface SimpleDateObject {
    year: number;
    month: number;
    day: number;
}

@Component({
    selector: 'app-planning',
    standalone: true,
    imports: [
        RouterLink,
        NgbInputDatepicker,
        ReactiveFormsModule,
        FormsModule,
        CdkDropList,
        EventLineComponent,
    ],
    templateUrl: './planning.component.html',
    styleUrl: './planning.component.css'
})
export class PlanningComponent {
    private router = inject(Router);
    private eventService = inject(EventService);
    private toast = inject(ToastService);

    readonly eventsLoaded = signal<boolean>(false);
    readonly planningLoaded = signal<boolean>(false);

    private eventsChanged$ = new Subject<void>();

    client: Client;
    events: FullEvent[] = [];
    fullEvents = signal<FullEvent[]>([]);
    dateSelector = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };

    constructor() {
        inject(UserService).currentClient.subscribe((client) => {
            this.client = client;

            this.onDateChange(this.dateSelector);

            this.eventService.allEventsFromClientId(this.client.id).subscribe((events) => {
                this.events = events;
                this.eventsLoaded.set(true);
            });
        });

        this.eventsChanged$.pipe(debounceTime(1000)).subscribe(() => this.saveEventList());
    }

    openEventForm() {
        this.router.navigateByUrl('/event/new');
    }

    onDateChange(event: SimpleDateObject) {
        this.planningLoaded.set(false);

        this.dateSelector = event;

        const date = new Date(event.year, event.month - 1, event.day);
        this.eventService.getClientFullEventList(this.client.id, date).subscribe(events => {
            this.fullEvents.set(events);
            this.planningLoaded.set(true);
        });
    }

    drop(event: CdkDragDrop<any, any>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.eventsChanged$.next();
    }

    removeEvent(index: number) {
        this.fullEvents().splice(index, 1);
        this.eventsChanged$.next();
    }

    private saveEventList() {
        const date = this.simpleDateToDate(this.dateSelector);
        const events = this.fullEvents().map((fullEvent) => fullEvent);

        this.eventService.saveClientList(events, this.client.id, date).subscribe(() => (
            this.toast.success($localize`The planning has been saved`)
        ));
    }

    private simpleDateToDate(simpleDate: SimpleDateObject): Date {
        const date = new Date();
        date.setFullYear(simpleDate.year);
        date.setMonth(simpleDate.month - 1);
        date.setDate(simpleDate.day);

        return date;
    }

    addToPlanning(event: FullEvent) {
        const existingEvent = this.fullEvents().find(e => e.id === event.id);
        if (existingEvent) {
            this.toast.error($localize`This event is already in the planning`);
            return;
        }

        this.fullEvents().push(event);
        this.eventsChanged$.next();
    }
}
