<div class="container mt-5">
    <div class="row">
        <div class="col-10 m-auto">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/client/profile"><i class="bx bx-home-alt fs-lg"></i></a>
                    </li>
                    <li class="breadcrumb-item"><a routerLink="/planning">Planning</a></li>
                    @if (event()?.id) {
                        <li class="breadcrumb-item active" aria-current="page">{{ event().title }}</li>
                    } @else {
                        <li class="breadcrumb-item active" aria-current="page" i18n>New event</li>
                    }
                </ol>
            </nav>

            @defer (when event()) {
                <form class="needs-validation">
                    @if (event().id) {
                        <h4 i18n>Edit an event</h4>
                    } @else {
                        <h4 i18n>Create an event</h4>
                    }
                    <div class="row">
                        <div class="col-2">
                            <div class="d-table position-relative mx-auto">
                                <app-resource-picker [(resource)]="event().resource"
                                                     [validating]="validate()"></app-resource-picker>
                            </div>
                        </div>
                        <div class="col-10">
                            <div class="form-floating mb-4">
                                <input class="form-control" [(ngModel)]="event().title" name="title" id="title-field"
                                       placeholder="Title" i18n-placeholder
                                       minlength="1" maxlength="15" required
                                       [ngClass]="inputTitleClass()"
                                       data-cy="event-title-form">
                                <label for="title-field" i18n>Title</label>
                                <div class="form-text ms-3" i18n>{{ event().title?.length ?? 0 }} / 15 allowed
                                    characters
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-10 offset-2 px-1" data-cy="time-selector-container">
                            <div class="form-check form-switch d-flex">
                                <div class="ms-2">
                                    <input type="checkbox" class="form-check-input" id="reminderSwitch"
                                           [checked]="displayStartingEvent()"
                                           (change)="toggleStartingEvent()">
                                    <label class="form-check-label" for="reminderSwitch">
                                        <span class="d-block h6 mb-2" i18n>Start time</span>
                                        <span class="d-block mb-2" i18n>
                                            Will send a notification on start. Format HH:MM
                                        </span>
                                    </label>
                                </div>
                            </div>

                            @if (displayStartingEvent()) {
                                <app-hour-selector [(time)]="event().startNotificationTime"></app-hour-selector>
                            }
                        </div>

<!--                        <div class="col-10 offset-2 mt-4 px-1">-->
<!--                            <div class="form-check form-switch d-flex">-->
<!--                                <div class="ms-2">-->
<!--                                    <input type="checkbox" class="form-check-input" id="voiceSwitch"-->
<!--                                           [checked]="displayVoiceRecording()"-->
<!--                                           (change)="toggleVoiceRecording()">-->
<!--                                    <label class="form-check-label" for="voiceSwitch">-->
<!--                                        <span class="d-block h6 mb-2" i18n>Add a recording</span>-->
<!--                                        <span class="d-block mb-2" i18n>Sound that will be played when clicking on the event (5 seconds maximum)</span>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            @if (displayVoiceRecording()) {-->
<!--                                <app-voice-recording [(voiceRecording)]="event().voiceRecording"></app-voice-recording>-->
<!--                            }-->
<!--                        </div>-->

                        @if (event().id) {
                            <div class="col-10 offset-2 mt-4 px-1 gap-3 d-flex big-buttons">
                            <div class="card text-white bg-success" style="cursor: pointer" (click)="modifyActions()">
                                <div class="card-body">
                                    <h6 class="card-title text-white" i18n>Modify actions</h6>
                                    <p class="card-text fs-sm" i18n>
                                        Actions are steps to accomplish an event.
                                    </p>
                                </div>
                            </div>
                            <div class="card text-white bg-warning" style="cursor: pointer" (click)="modifyPrizes()">
                                <div class="card-body">
                                    <h6 class="card-title text-white" i18n>Modify prizes</h6>
                                    <p class="card-text fs-sm" i18n>
                                        Add rewards to positively reinforce the end of an event.
                                    </p>
                                </div>
                            </div>
                            </div>
                        }

<!--                        @if (event().id) {-->
<!--                            <div class="col-10 offset-2 mt-4 px-1">-->
<!--                                <div class="form-check form-switch d-flex">-->
<!--                                    <div class="ms-2">-->
<!--                                        <input type="checkbox" class="form-check-input" id="actionsSwitch"-->
<!--                                               (click)="toggleActions()" [checked]="displayActions()">-->
<!--                                        <label class="form-check-label" for="actionsSwitch">-->
<!--                                            <span class="d-block h6 mb-2" i18n>Prizes</span>-->
<!--                                            <span class="d-block mb-2" i18n>-->
<!--                                                Add actions to succeed in the event-->
<!--                                            </span>-->
<!--&lt;!&ndash;                                            <span class="d-block mb-2" i18n>&ndash;&gt;-->
<!--&lt;!&ndash;                                                You currently have {{ event().actions?.length ?? 0 }} actions linked to this event&ndash;&gt;-->
<!--&lt;!&ndash;                                            </span>&ndash;&gt;-->

<!--                                        </label>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <p>Vous avez 3 actions liées à cet événement</p>-->
<!--                                <p>-->
<!--                                    Vous avez-->
<!--                                <span class="badge bg-success rounded-pill btn me-2">-->
<!--                                        {{ event().actions.length }} actions</span>liées à cet événement-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        }-->

<!--                        @if (event().id) {-->
<!--                            <div class="col-10 offset-2 mt-4 px-1">-->
<!--                                <div class="form-check form-switch d-flex">-->
<!--                                    <div class="ms-2">-->
<!--                                        <input type="checkbox" class="form-check-input" id="prizesSwitch"-->
<!--                                               (click)="togglePrizes()" [checked]="displayPrizes()">-->
<!--                                        <label class="form-check-label" for="prizesSwitch">-->
<!--                                            <span class="d-block h6 mb-2" i18n>Prizes</span>-->
<!--                                            <span class="d-block mb-2" i18n>-->
<!--                                                Add rewards to positively reinforce the end of an event-->
<!--                                            </span>-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        }-->

<!--                        @if (displayActions()) {-->
<!--                            @defer (when displayActions()) {-->
<!--                                <hr class="my-5">-->
<!--                                <app-actions [actions]="event().actions"-->
<!--                                             (actionsChange)="updateActions($event)"></app-actions>-->

<!--                            } @loading (minimum 100ms) {-->
<!--                                <div class="d-flex justify-content-center align-items-center mt-5">-->
<!--                                    <div class="spinner-grow text-success" role="status">-->
<!--                                        <span class="visually-hidden">Loading...</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            }-->
<!--                        }-->

<!--                        @if (displayPrizes()) {-->
<!--                            @defer (when displayPrizes()) {-->
<!--                                <hr class="my-5">-->
<!--                                <app-prizes [prizes]="event().prizes"-->
<!--                                            (prizesChange)="updatePrizes($event)"></app-prizes>-->

<!--                            } @loading (minimum 100ms) {-->
<!--                                <div class="d-flex justify-content-center align-items-center mt-5">-->
<!--                                    <div class="spinner-grow text-warning" role="status">-->
<!--                                        <span class="visually-hidden">Loading...</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            }-->
<!--                        }-->
                    </div>

                    <hr class="mt-5 mb-3">

                    <div class="d-flex justify-content-between mb-5">
                        <button type="button" class="btn btn-secondary mx-1" routerLink="/planning"
                                [disabled]="saving()"
                                i18n>Cancel
                        </button>
                        <div>
                            @if (event().id) {
                                <button type="button" class="btn btn-outline-danger me-2" [disabled]="saving()" i18n
                                        data-bs-toggle="modal" data-bs-target="#deleteModal">
                                    Delete
                                </button>
                            }

                            @if (saving()) {
                                <button type="button" class="btn btn-primary pe-none mx-1">
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                                    <span class="visually-hidden">Saving...</span>
                                </span>
                                    <ng-container i18n>Saving...</ng-container>
                                </button>
                            } @else {
                                <button type="button" class="btn btn-primary" data-cy="save-event" (click)="save()"
                                        i18n>
                                    Save
                                </button>
                            }
                        </div>
                    </div>
                </form>

                <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
                     aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="deleteModalLabel" i18n>Delete an event</h1>
                                <button #close type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p i18n>Are you sure you want to delete this event ?</p>
                                <p><em i18n>None of your actions or prizes will be deleted.</em></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>
                                    Cancel
                                </button>
                                @if (deleting()) {
                                    <button type="button" class="btn btn-danger">
                                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                                          <span class="visually-hidden">Deleting...</span>
                                        </span>
                                        <ng-container i18n>Deleting...</ng-container>
                                    </button>
                                } @else {
                                    <button type="button" class="btn btn-danger" i18n (click)="delete()">
                                        Delete
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            } @loading (minimum 10ms) {
                is loading...
            }
        </div>
    </div>
</div>

<div class="modal fade" id="edit-entity" tabindex="-1" aria-labelledby="deleteModalLabel"
     aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="edit-entity-label" i18n>Delete an event</h1>
                <button #close type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <router-outlet name="modal"></router-outlet>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
