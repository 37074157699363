import {Component, inject, signal} from '@angular/core';
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {UserService} from "@services/user.service";
import {Action} from "@models/action";
import {ActionService} from "@services/action.service";
import {EventService} from "@services/event.service";
import {FullEvent} from "@models/full-event";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {ToastService} from "@services/toast.service";

@Component({
    selector: 'app-set-actions',
    standalone: true,
    imports: [
        CdkDrag,
        CdkDropList,
        RouterLink
    ],
    templateUrl: './set-actions.component.html',
    styleUrl: './set-actions.component.css'
})
export class SetActionsComponent {
    private eventService = inject(EventService);
    private toast = inject(ToastService);

    private actionsChanged$ = new Subject<void>();

    protected client = inject(UserService).currentClient.value;

    allActions: Action[] = [];

    event = signal<FullEvent>(null);
    actions = signal<Action[]>([]);
    saving = signal(false);
    actionsLoaded = signal(false);

    constructor() {
        inject(ActionService).getAllFromClientId(this.client.id).subscribe(allActions => {
            this.allActions = allActions;
            this.actionsLoaded.set(true);
        });

        inject(ActivatedRoute).params.subscribe((params) => (
            inject(EventService).get(params.id).subscribe((event) => {
                this.event.set(event);
                this.actions.set(event.actions)
            })
        ));

        this.actionsChanged$.pipe(debounceTime(1000)).subscribe(() => this.save());
    }

    drop(event: CdkDragDrop<any, any>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.actionsChanged$.next();
    }

    addToEvent(action: Action) {
        this.actions().push(action);
        this.actionsChanged$.next();
    }

    removeAction(action: Action) {
        const index = this.actions().indexOf(action);
        this.actions().splice(index, 1);
        this.actionsChanged$.next();
    }

    save() {
        this.saving.set(true);
        this.event().actions = this.actions();

        this.eventService.save(this.event()).subscribe(() => {
            this.toast.success($localize`Actions have been affected`);
        })
    }
}
