import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, tap} from "rxjs";
import {AuthService} from "@auth0/auth0-angular";
import {Plan} from "@models/plan";

@Injectable({
    providedIn: 'root'
})
export class CreateSupervisorService {
    private authService = inject(AuthService);

    private userSignedUp$ = new BehaviorSubject<boolean>(null);
    private userPlan$ = new BehaviorSubject<Plan | false | null>(null);
    private hasPayed$ = new BehaviorSubject<boolean>(null);
    private hasTrial$ = new BehaviorSubject<boolean>(null);

    get isUserSignedUp(): BehaviorSubject<boolean> {
        return <BehaviorSubject<boolean>>this.userSignedUp$.pipe(tap(() => {
            if (this.userSignedUp$.value === true) {
                return;
            }

            this.authService.isAuthenticated$.subscribe((authenticated) => {
                if (!authenticated) {
                    return;
                }
                this.authService.getAccessTokenSilently({cacheMode: "off"}).subscribe(() => {
                    this.authService.user$.subscribe((user) => {
                        if (user['https://plan-timer.com/app_metadata']['signed_up']) {
                            this.userSignedUp$.next(true);
                        } else if (this.userSignedUp$.value === null) {
                            this.userSignedUp$.next(false);
                        }
                    });
                });
            });
        }));
    }

    get hasPlan(): BehaviorSubject<Plan | false | null> {
        return <BehaviorSubject<Plan | false | null>>this.userPlan$.pipe(tap(() => {
            if (this.userPlan$.value !== null) {
                return;
            }

            this.authService.isAuthenticated$.subscribe((authenticated) => {
                if (!authenticated) {
                    return;
                }
                this.authService.getAccessTokenSilently({cacheMode: "off"}).subscribe(() => {
                    this.authService.user$.subscribe((user) => {
                        const appMetadata = user['https://plan-timer.com/app_metadata'];

                        if (appMetadata['plan_id']) {
                            this.userPlan$.next(new Plan(appMetadata['plan_id']));
                        } else if (this.userPlan$.value === null) {
                            this.userPlan$.next(false);
                        }
                    });
                });
            });
        }));
    }

    async waitForNewPlan(current: Plan, next: Plan): Promise<Plan | false> {
        let attempts = 0;

        do {
            if (current === next) {
                return current;
            }

            const userProviderPlan = await new Promise<Plan>((r) => {
                this.authService.getAccessTokenSilently({cacheMode: "off"}).subscribe(() => {
                    this.authService.user$.subscribe((user) => {
                        const appMetadata = user['https://plan-timer.com/app_metadata'];

                        if (appMetadata['plan_id']) {
                            r(new Plan(appMetadata['plan_id']));
                            return;
                        }

                        r(null);
                    });
                });
            });

            if (userProviderPlan?.id === next.id) {
                this.userPlan$.next(userProviderPlan);
                return userProviderPlan;
            }

            await new Promise<void>((r) => setTimeout(r, 3000));
        } while (attempts++ < 10);

        return this.userPlan$.value;
    }

    get hasPaid(): BehaviorSubject<boolean> {
        return <BehaviorSubject<boolean>>this.hasPayed$.pipe(tap(() => {
            if (this.hasPayed$.value !== null) {
                return;
            }

            this.authService.isAuthenticated$.subscribe((authenticated) => {
                if (!authenticated) {
                    return;
                }
                this.authService.getAccessTokenSilently().subscribe(() => {
                    this.authService.user$.subscribe((user) => {
                        if (user['https://plan-timer.com/app_metadata']['subscription_id']) {
                            this.hasPayed$.next(true);
                        } else {
                            this.hasPayed$.next(false);
                        }
                    });
                });
            });
        }));
    }

    get hasTrial(): BehaviorSubject<boolean> {
        return <BehaviorSubject<boolean>>this.hasTrial$.pipe(tap(() => {
            if (this.hasTrial$.value !== null) {
                return;
            }

            this.authService.isAuthenticated$.subscribe((authenticated) => {
                if (!authenticated) {
                    return;
                }
                this.authService.getAccessTokenSilently().subscribe(() => {
                    this.authService.user$.subscribe((user) => {
                        const appMetadata = user['https://plan-timer.com/app_metadata'];
                        const endTrial = new Date(appMetadata['end_trial']);

                        if (new Date() < endTrial) {
                            this.hasTrial$.next(true);
                        } else {
                            this.hasTrial$.next(false);
                        }
                    });
                });
            });
        }));
    }

    async waitForSubscription(): Promise<boolean> {
        let attempts = 0;

        do {
            const hasSubscription = await new Promise<boolean>((r) => {
                this.authService.getAccessTokenSilently({cacheMode: "off"}).subscribe(() => {
                    this.authService.user$.subscribe((user) => {
                        const appMetadata = user['https://plan-timer.com/app_metadata'];

                        if (appMetadata['subscription_id']) {
                            r(true);
                            return;
                        }

                        r(false);
                    });
                });
            });

            if (hasSubscription) {
                this.hasPayed$.next(true);
                return true;
            }

            await new Promise<void>((r) => setTimeout(r, 3000));
        } while (attempts++ < 10);

        return false;
    }

    hasSignedUp() {
        this.userSignedUp$.next(null);
    }
}
