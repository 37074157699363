import {Component, computed, inject, signal, WritableSignal} from '@angular/core';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {HourSelectorComponent} from "@components/front/hour-selector/hour-selector.component";
import {ResourcePickerComponent} from "@components/front/resource-picker/resource-picker.component";
import {VoiceRecordingComponent} from "@components/front/voice-recording/voice-recording.component";
import {UserService} from "@services/user.service";
import {EventService} from "@services/event.service";
import {NgClass} from "@angular/common";
import {ToastService} from "@services/toast.service";
import {FullEvent} from "@models/full-event";

@Component({
    selector: 'app-event',
    standalone: true,
    imports: [
        RouterOutlet,
        FormsModule,
        HourSelectorComponent,
        ResourcePickerComponent,
        VoiceRecordingComponent,
        NgClass,
        RouterLink
    ],
    templateUrl: './event.component.html',
    styleUrl: './event.component.css'
})
export class EventComponent {
    private readonly eventService = inject(EventService);
    private readonly toast = inject(ToastService);
    private readonly router = inject(Router);

    private readonly client = inject(UserService).currentClient.value;

    protected readonly navEventTitle = signal<string>('New event');
    readonly validate = signal(false);
    readonly saving = signal(false);
    readonly deleting = signal(false);
    readonly displayStartingEvent = signal(false);
    readonly displayVoiceRecording = signal(false);
    readonly displayActions = signal(false);
    readonly displayPrizes = signal(false);

    event: WritableSignal<FullEvent> = signal(null);

    inputTitleClass = computed(() => {
        return this.validate() ? {'is-valid': this.event().title, 'is-invalid': !this.event().title} : {}
    });

    constructor() {
        inject(ActivatedRoute).params.subscribe(params => {
            if (!params.id) {
                this.event.set(new FullEvent(null, null, null, null));
                return;
            }

            this.eventService.get(params.id).subscribe(event => {
                this.navEventTitle.set(event.title);
                this.event = signal(event);
                this.displayStartingEvent.set(!!event.startNotificationTime);
                this.displayVoiceRecording.set(!!event.voiceRecording);
                this.displayActions.set(!!event.actions.length);
                this.displayPrizes.set(!!event.prizes.length);
            });
        });
    }

    save() {
        this.validate.set(true);

        if (!(this.event().title && this.event().resource)) {
            return;
        }

        this.saving.set(true);
        this.eventService.save(this.event(), this.client.id).subscribe({
            next: (event) => {
                this.saving.set(false);
                this.validate.set(false);
                this.toast.success($localize`Event saved`);
                this.router.navigate(['event', event.id, 'edit'])
            },
            error: () => {
                this.saving.set(false);
                this.validate.set(false);
                this.toast.error($localize`An error occurred while saving the event`);
            }
        });
    }

    delete() {
        this.deleting.set(true);

        this.eventService.delete(this.event().id).subscribe({
            next: () => {
                this.toast.success($localize`Event deleted`);
                this.router.navigateByUrl('/planning');
            },
            error: () => {
                this.deleting.set(false);
                this.toast.error($localize`An error occurred while deleting the event`);
            }
        });
    }

    toggleStartingEvent() {
        this.displayStartingEvent.set(!this.displayStartingEvent());
    }

    toggleVoiceRecording() {
        this.displayVoiceRecording.set(!this.displayVoiceRecording());
    }

    modifyActions() {
        this.router.navigate(['/', 'event', this.event().id, 'actions']);
    }

    modifyPrizes() {
        this.router.navigate(['/', 'event', this.event().id, 'prizes']);
    }
}
