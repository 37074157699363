export class PlanningConfiguration {
    constructor(private _tokensEnabled: boolean, private _cheatExtinction: boolean, private _vocalAssistant: boolean) {
    }

    get tokensEnabled(): boolean {
        return this._tokensEnabled;
    }

    get cheatExtinction(): boolean {
        return this._cheatExtinction;
    }

    get vocalAssistant(): boolean {
        return this._vocalAssistant;
    }
}
