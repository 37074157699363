import {Component, inject} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {HeaderComponent} from '../shared/components/header/header.component';
import {LoadingService} from "@services/loading.service";
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {combineLatest, filter, map} from "rxjs";

@Component({
    selector: 'app-front',
    templateUrl: './front.component.html',
    standalone: true,
    imports: [HeaderComponent, RouterOutlet]
})
export class FrontComponent {
    constructor() {
        const createSupervisorService = inject(CreateSupervisorService);
        const router = inject(Router);

        combineLatest([createSupervisorService.hasPlan, createSupervisorService.hasTrial, createSupervisorService.hasPaid])
            .pipe(
                filter(([hasPlan, hasTrial, hasPaid]) => hasPlan !== null && hasTrial !== null && hasPaid !== null),
                map(([hasPlan, hasTrial, hasPaid]) => {
                    return !!hasPlan && (hasTrial || hasPaid);
                })
            )
            .subscribe((hasActiveSubscription) => {
                if (!hasActiveSubscription) {
                    router.navigate(['/', 'account', 'profile']);
                    return;
                }

                LoadingService.loading$.next(false);
            });
    }
}
