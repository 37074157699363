<div class="container row mt-5 mx-auto">
    @defer (when actionsLoaded()) {
        <div class="col-4 overflow-auto">
            <div class="card text-white bg-success" style="cursor: pointer" routerLink="/action/new">
                <div class="card-body">
                    <h6 class="card-title text-white" i18n>New action</h6>
                    <p class="card-text fs-sm" i18n>Create an action and add it to {{ client.firstName }}'s
                        list</p>
                </div>
            </div>
            @for (action of allActions; track action.id) {
                <div class="d-flex justify-content-between border rounded-3 my-3 flex-grow-1"
                     style="height: 75px;">
                    <div class="d-flex">
                        <div class="h-100 rounded-start-3 bg-success" style="width: 20px;"></div>
                        <img [src]="action.resource.Link" class="me-3" style="height: 100%" alt="">
                        <a class="m-0 py-1" [routerLink]="['/action', action.id, 'edit']">{{ action.title }}</a>
                    </div>

                    <div class="pen rounded-end-3 d-flex justify-content-center align-items-center"
                         style="width: 40px;"
                         (click)="addToEvent(action)">
                        <i class="bx bx-chevrons-right fs-3"></i>
                    </div>
                </div>
            }
        </div>

        <div class="col-8 ps-5 overflow-auto">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a routerLink="/client/profile"><i class="bx bx-home-alt fs-lg"></i></a>
                    </li>
                    <li class="breadcrumb-item"><a routerLink="/planning">Planning</a></li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/', 'event', event().id, 'edit']">{{ event().title }}</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Affectation des actions</li>
                </ol>
            </nav>

            <h5 class="fw-normal">Affectation des actions à l'événement <i>{{ event().title}}</i></h5>
            <div
                (cdkDropListDropped)="drop($event)"
                [cdkDropListData]="actions()"
                cdkDropList
                cdkDropListLockAxis="y"
                cdkDropListOrientation="vertical"
            >
                @for (action of actions(); let idx = $index; track idx) {
                    <div class="d-flex align-items-center gap-3 full-event" cdkDrag>
                        <div>
                            <!-- Jetons ? Minuteur ? -->
                        </div>
                        <div class="d-flex justify-content-between border rounded-3 my-3 flex-grow-1"
                             style="height: 75px;">
                            <div class="d-flex">
                                <div class="h-100 rounded-start-3 bg-success" style="width: 20px;"></div>
                                <img [src]="action.resource.Link" class="me-3" style="height: 100%" alt="">
                                <div class="d-flex flex-column justify-content-between py-1">
                                    <p class="m-0">{{ action.title }}</p>
                                    <div>
                                        <!-- badges -->
                                    </div>
                                </div>
                            </div>
                            <div class="pen rounded-end-3 d-flex justify-content-center align-items-center"
                                 style="width: 40px;"
                                 routerLink="/action/{{ action.id }}/edit"
                            >
                                <i class="bx bx-edit-alt fs-3"></i>
                            </div>
                        </div>
                        <div class="remove-event" (click)="removeAction(action)">
                            <i class="bx bxs-x-circle fs-4"></i>
                        </div>
                    </div>
                } @empty {
                    <div class="d-flex flex-column p-3 align-items-center">
                        <p class="text-center" i18n>No action linked.</p>
                        <img src="assets/add-element.svg" alt="Add events to the planning" i18n-alt
                             width="200"
                             height="200">
                        <div class="text-center">
                            <button class="btn btn-success mt-2" routerLink="/action/new" i18n>Create an action</button>
                        </div>
                    </div>
                }
            </div>
        </div>


    } @loading (minimum 500ms) {
        <div class="d-flex justify-content-center align-items-center mt-5">
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    } @error {
        ERROR
    }
</div>
