<h4 i18n>User</h4>
<form [formGroup]="userForm()" class="needs-validation"
      [ngClass]="{'was-validated': userValidated()}" novalidate>
    <div class="row pb-2">
        <div class="col-sm-6 mb-4">
            <label for="username" class="form-label fs-base" i18n>Username</label>
            <input id="username" class="form-control form-control-lg" formControlName="username">
        </div>

        <div class="col-sm-6 mb-4">
            <label for="password" class="form-label fs-base" i18n>Password</label>
            <input type="password" id="password" class="form-control form-control-lg"
                   formControlName="password" required minlength="8">
            <div class="invalid-feedback" i18n>The password must be at least 8 characters long</div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-end mb-3">
        <save-button [save]="savingUser()" (saving)="saveUser()">Save</save-button>
    </div>
</form>
