import {inject, Injectable} from '@angular/core';
import {PlanningConfigurationResponse} from "@services/planning.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PlanningRepository {
    private httpClient = inject(HttpClient);

    getConfiguration(clientId: string): Observable<PlanningConfigurationResponse> {
        return this.httpClient.get<PlanningConfigurationResponse>(`/clients/${clientId}/settings`);
    }

    saveConfiguration(clientId: string, tokensEnabled: boolean, cheatExtinction: boolean, vocalAssistant: boolean): Observable<PlanningConfigurationResponse> {
        return this.httpClient.put<PlanningConfigurationResponse>(`/clients/${clientId}/settings`, {
            tokens_enabled: tokensEnabled,
            cheat_extinction: cheatExtinction,
            vocal_assistant: vocalAssistant,
        });
    }
}
