<h4 i18n>Settings</h4>
@defer (when configurationForm()) {
    <form [formGroup]="configurationForm()" class="needs-validation"
          [ngClass]="{'was-validated': configurationValidated()}"
          novalidate>
        <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" id="tokens" formControlName="tokens">
            <label class="form-check-label" for="tokens">
                <span class="h6 d-block mb-2" i18n>Tokens</span>
                <span class="fs-sm text-muted"
                      i18n>Turn on to offer tokens to your client linked to certain actions</span>
            </label>
        </div>
        <div class="form-check form-switch my-4">
            <input type="checkbox" class="form-check-input" id="cheatExtinction"
                   formControlName="cheatExtinction">
            <label class="form-check-label" for="cheatExtinction">
                <span class="h6 d-block mb-2" i18n>Cheat extinction</span>
                <span class="fs-sm text-muted"
                      i18n>Turn on the cheat extinction to avoid skipping actions</span>
            </label>
        </div>
        <div class="form-check form-switch my-4">
            <input type="checkbox" class="form-check-input" id="voiceRecording" formControlName="vocalAssistant">
            <label class="form-check-label" for="voiceRecording">
                <span class="h6 d-block mb-2" i18n>Voice recordings</span>
                <span
                    class="fs-sm text-muted"
                    i18n>Enable voice recordings that will be played for each events or actions</span>
            </label>
        </div>

        <div class="d-flex flex-column align-items-end mb-5">
            <save-button [save]="savingConfiguration()" (saving)="saveConfiguration()">Save</save-button>
        </div>
    </form>
}
