import {Component, inject, signal} from '@angular/core';
import {UserService} from "@services/user.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {NgClass} from '@angular/common';
import {SaveButtonComponent} from "../../../../shared/components/save-button/save-button.component";
import {ToastService} from "@services/toast.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, SaveButtonComponent]
})
export class ProfileComponent {
    private userService = inject(UserService);
    private toastService = inject(ToastService);

    protected readonly client = this.userService.currentClient.value;
    protected readonly currentYear = new Date().getFullYear();

    protected readonly profileForm = signal<FormGroup | null>(null);
    protected readonly savingProfile = signal<boolean>(false);
    protected readonly profileValidated = signal<boolean>(false);

    constructor() {
        const profileForm = new FormGroup({
            firstName: new FormControl<string>(this.client.firstName, [Validators.required]),
            lastName: new FormControl<string>(this.client.lastName, [Validators.required]),
            yearOfBirth: new FormControl<number>(this.client.birth, [Validators.required, this.validateBirthYear()]),
            gender: new FormControl<string>(this.client.gender, [Validators.required, Validators.pattern(/^([mf])$/)]),
        });
        this.profileForm.set(profileForm);
    }

    private validateBirthYear(): ValidatorFn {
        return (control: FormControl) => {
            const birthYear = control.value;
            const currentYear = new Date().getFullYear();

            return birthYear > 1900 && birthYear <= currentYear ? null : {invalidBirthYear: true};
        };
    }

    saveProfile() {
        this.profileValidated.set(true);
        if (!this.profileForm().valid) {
            return;
        }

        this.savingProfile.set(true);

        this.client.firstName = this.profileForm().value.firstName;
        this.client.lastName = this.profileForm().value.lastName;
        this.client.birth = Number(this.profileForm().value.yearOfBirth);
        this.client.gender = this.profileForm().value.gender;

        this.userService.saveClient(this.client).subscribe({
            next: () => {
                this.savingProfile.set(false);
                this.profileValidated.set(true);
                this.toastService.success('Profile saved');
            },
            error: (a) => {
                this.savingProfile.set(false);
                this.toastService.error('Error saving profile');
            }
        });
    }
}
